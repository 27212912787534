class Socials {
    constructor(element) {
        this.element = element;
        this.classNames = {
            share: `js--share`,
        };
        this.shareLinks = Array.from(this.element.querySelectorAll(`.${this.classNames.share}`));
        this.platforms = {
            x: 'http://x.com/share?url=',
            facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
            linkedin: 'https://www.linkedin.com/shareArticle?mini=true&url=',
            whatsapp: 'https://api.whatsapp.com/send?text=',
        };
        this.initialize();
    }
    initialize() {
        this.addEventListeners();
    }
    addEventListeners() {
        this.shareLinks.forEach((el) => {
            el.addEventListener('click', this.handleClick.bind(this));
        });
    }
    handleClick(event) {
        const currentTarget = event.currentTarget;
        const config = currentTarget.dataset.config ? JSON.parse(currentTarget.dataset.config) : {};
        const text = config.shareText || i18n.socialShare;
        const body = `${window.location.href}&text=${text}`;
        const location = {
            x: `${this.platforms.x}${body}`,
            facebook: `${this.platforms.facebook}${body}`,
            linkedin: `${this.platforms.linkedin}${body}`,
            whatsapp: `${this.platforms.whatsapp}${text}: ${window.location.href}`,
        };
        if (config.type === 'email') {
            currentTarget.setAttribute('href', `mailto:?subject=${text}&body=${window.location.href}`);
        }
        else if (typeof location[config.type] !== 'undefined') {
            const width = 550;
            const height = 450;
            const top = window.screen.height / 2 - height / 2;
            window.open(location[config.type], location[config.type].charAt(0).toUpperCase() + location[config.type].slice(1), `height=${height}, width=${width} top=${top} toolbar=0, location=0, menubar=0, directories=0, scrollbars=0`);
            event.preventDefault();
        }
    }
}
export default Socials;
