import TabFocusHelper from "./tab-focus-helper";
import ResponsiveHelper from "./responsive-helper";
import { scrollLock } from "./scroll-lock";
const configDefaults = {
    trigger: null,
    target: null,
    disableScroll: false,
    disableTrap: false,
    customScrollableElement: null,
    focusInputClass: null,
    focusInput: false,
    defaultButtonText: null,
    closeButtonText: null,
    isChildToggle: false,
};
export default class MrToggle {
    constructor(element, options) {
        var _a;
        this.element = element;
        this.options = options;
        this._moduleName = ((_a = this.options) === null || _a === void 0 ? void 0 : _a.moduleName) ? this.options.moduleName : "";
        this.config = this.element.dataset.config ? Object.assign(Object.assign({}, configDefaults), JSON.parse(this.element.dataset.config)) : {};
        this._triggers = [];
        this._targets = [];
        this._tabFocus = new TabFocusHelper();
        this._responsiveHelper = new ResponsiveHelper();
        this._buttonTextTarget = ".btn__text, [data-button-text]";
        if (typeof this.config.trigger === "undefined") {
            console.warn("MrToggle: Trigger property not found in data-config"); // eslint-disable-line no-console
            return;
        }
        if (typeof this.config.target === "undefined") {
            console.warn("MrToggle: Target property not found in data-config"); // eslint-disable-line no-console
            return;
        }
        this.reset();
        this.addEventListeners();
    }
    /**
     * Loop though all toggle modules and add them to two arrays: _triggers and _targets
     * These items will be used to reset any other toggles
     */
    reset() {
        var _a;
        if (((_a = this._moduleName) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            return;
        }
        const modules = Array.from(document.querySelectorAll(`[data-module="${this._moduleName}"]`));
        modules.forEach((item) => {
            const itemConfig = item.dataset.config
                ? Object.assign(Object.assign({}, configDefaults), JSON.parse(item.dataset.config)) : null;
            if (!itemConfig) {
                return;
            }
            if (itemConfig.trigger) {
                const trigger = document.querySelector(itemConfig.trigger);
                if (trigger) {
                    this._triggers.push(trigger);
                }
            }
            if (itemConfig.target) {
                const target = document.querySelector(itemConfig.target);
                if (target) {
                    this._targets.push(target);
                }
            }
        });
    }
    /**
     * Close all toggles
     */
    closeAllToggles() {
        this.close();
    }
    /**
     * Adds event listeners
     */
    addEventListeners() {
        var _a;
        if (!this.config.trigger) {
            return;
        }
        document.querySelectorAll(this.config.trigger).forEach((trigger) => {
            trigger.addEventListener("click", this.handleClick.bind(this));
        });
        this.element.addEventListener("keydown", this.handleKeyboard.bind(this));
        // Use mouseenter and leave when useHover option is true and user is not on a real device
        if (((_a = this.options) === null || _a === void 0 ? void 0 : _a.useHover) && !this._responsiveHelper.isRealDevice()) {
            this.element.addEventListener("mouseenter", this.open.bind(this));
            this.element.addEventListener("mouseleave", this.handleMouseLeave.bind(this));
        }
    }
    /**
     * Call open or close function based on aria-expanded
     */
    handleClick() {
        var _a;
        if (!this.config.trigger) {
            return;
        }
        if (((_a = document.querySelector(this.config.trigger)) === null || _a === void 0 ? void 0 : _a.getAttribute("aria-expanded")) === "false") {
            this.open();
        }
        else {
            this.close();
        }
    }
    open() {
        if (!this.config.trigger || !this.config.target) {
            return;
        }
        // Reset toggles first, only if `isChildToggle` is false
        if (!this.config.isChildToggle) {
            this.resetAllToggles();
        }
        const trigger = document.querySelector(this.config.trigger);
        const target = document.querySelector(this.config.target);
        if (!trigger || !target) {
            return;
        }
        // Open toggle using aria logic
        trigger.setAttribute("aria-expanded", "true");
        const dataExpanded = trigger.getAttribute("data-expanded");
        if (dataExpanded) {
            trigger.setAttribute("aria-label", dataExpanded);
        }
        target.setAttribute("aria-hidden", "false");
        // If disableScroll is enabled
        if (this.config.disableScroll) {
            let customScrollableElement = null;
            if (this.config.customScrollableElement) {
                customScrollableElement = document.querySelector(this.config.customScrollableElement);
            }
            // Disable scroll for custom element if it exists
            if (customScrollableElement) {
                scrollLock.disableScroll(customScrollableElement);
            }
            else {
                scrollLock.disableScroll(target);
            }
        }
        // When default button text and close button text is set,
        // Set the close button text in the button
        if (this.config.closeButtonText && this.config.defaultButtonText) {
            const btnText = trigger.querySelector(this._buttonTextTarget);
            if (btnText instanceof HTMLElement) {
                btnText.textContent = this.config.closeButtonText;
            }
        }
        // FocusInput is enabled
        // If a specific input class is set, open that one, else open first available input
        if (this.config.focusInput) {
            setTimeout(() => {
                let $input = target.querySelector("input");
                if (this.config.focusInputClass) {
                    $input = target.querySelector(this.config.focusInputClass);
                }
                $input === null || $input === void 0 ? void 0 : $input.focus();
            }, 100);
        }
        // Trigger custom event
        this.element.dispatchEvent(new Event("openMrToggle"));
    }
    close() {
        if (!this.config.trigger || !this.config.target) {
            return;
        }
        const trigger = document.querySelector(this.config.trigger);
        const target = document.querySelector(this.config.target);
        if (!trigger || !target) {
            return;
        }
        // Close toggle using aria logic
        trigger.setAttribute("aria-expanded", "false");
        const dataCollapsed = trigger.getAttribute("data-collapsed");
        if (dataCollapsed) {
            trigger.setAttribute("aria-label", dataCollapsed);
        }
        target.setAttribute("aria-hidden", "true");
        // When default button text and close button text is set,
        // Set the default button text in the button
        if (this.config.closeButtonText && this.config.defaultButtonText) {
            const btnText = trigger.querySelector(this._buttonTextTarget);
            if (btnText instanceof HTMLElement) {
                btnText.textContent = this.config.defaultButtonText;
            }
        }
        // If disableScroll is enabled
        if (this.config.disableScroll) {
            scrollLock.clearAllLocks();
        }
        // Focus the trigger after closing the toggle
        trigger.focus();
        // Trigger custom event
        this.element.dispatchEvent(new Event("closeMrToggle"));
    }
    handleMouseLeave() {
        // Close the toggle when useHover is true and user leaves the toggle
        this.close();
        if (!this.config.trigger) {
            return;
        }
        // Remove focus from the trigger set by the close function
        const trigger = document.querySelector(this.config.trigger);
        trigger === null || trigger === void 0 ? void 0 : trigger.blur();
    }
    handleKeyboard(event) {
        var _a;
        if (event.key === "Escape") {
            this.close();
        }
        if (this.config.disableTrap) {
            return;
        }
        if (event.key === "Tab" && this.config.trigger) {
            if (((_a = document.querySelector(this.config.trigger)) === null || _a === void 0 ? void 0 : _a.getAttribute("aria-expanded")) === "true") {
                this._tabFocus.trap(this.element, event);
            }
        }
    }
    /**
     * Resets all _triggers by setting aria-expanded to false and set their data-collapsed text
     */
    resetAllToggles() {
        this._triggers.forEach((toggle) => {
            toggle.setAttribute("aria-expanded", "false");
            const dataCollapsed = toggle.getAttribute("data-collapsed");
            if (dataCollapsed) {
                toggle.setAttribute("aria-label", dataCollapsed);
            }
            const closestModule = toggle.closest(`[data-module="${this._moduleName}"]`);
            const toggleConfig = (closestModule === null || closestModule === void 0 ? void 0 : closestModule.dataset.config) ? JSON.parse(closestModule.dataset.config) : null;
            // When default button text and close button text is set,
            // Set the default button text in the buttons
            if ((toggleConfig === null || toggleConfig === void 0 ? void 0 : toggleConfig.closeButtonText) && (toggleConfig === null || toggleConfig === void 0 ? void 0 : toggleConfig.defaultButtonText)) {
                const btnText = toggle.querySelector(this._buttonTextTarget);
                if (btnText instanceof HTMLElement) {
                    btnText.textContent = toggleConfig.defaultButtonText ? toggleConfig.defaultButtonText : "";
                }
            }
            if (toggleConfig.disableScroll) {
                scrollLock.clearAllLocks();
            }
        });
        // Loop through all _targets
        // Reset all _targets by setting aria-hidden to true
        this._targets.forEach((target) => {
            target.setAttribute("aria-hidden", "true");
        });
    }
}
