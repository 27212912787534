import { getAllFocusableElements, getFirstFocusableElement } from "./focusable-elements";
export default class TabFocusHelper {
    /**
     * Trap the forward/back tab inside the given element
     *
     * @param  element
     * @param  event
     */
    trap(element, event) {
        // Get list of focusable items
        const focusableItems = getAllFocusableElements(element);
        // Get currently focused item
        const focusedItem = document.activeElement;
        // Get the number of focusable items
        const numberOfFocusableItems = focusableItems.length;
        // Get the index of the currently focused item
        const focusedItemIndex = focusableItems.findIndex((item) => item === focusedItem);
        if (event.shiftKey) {
            // Back tab
            // If focused on first item and user preses back-tab, go to the last focusable item
            if (focusedItemIndex === 0) {
                const lastFocusableItem = focusableItems[numberOfFocusableItems - 1];
                lastFocusableItem === null || lastFocusableItem === void 0 ? void 0 : lastFocusableItem.focus();
                event.preventDefault();
            }
        }
        else if (focusedItemIndex === numberOfFocusableItems - 1) {
            // Forward tab
            // If focused on the last item and user preses tab, go to the first focusable item
            if (focusableItems.length > 0) {
                const firstFocusableItem = getFirstFocusableElement(element);
                firstFocusableItem.focus();
                event.preventDefault();
            }
        }
    }
}
