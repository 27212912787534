class PrimaryNav {
    constructor(element) {
        var _a;
        this.element = element;
        this.classNames = {
            parent: `js-parent-item`,
            item: `js-item`,
            toggle: `js-toggle`,
            submenu: `js-submenu`,
        };
        this.scrolledElement = (_a = this.element.parentElement) === null || _a === void 0 ? void 0 : _a.parentElement;
        this.parentItems = Array.from(element.querySelectorAll(`.${this.classNames.parent}`));
        this.items = Array.from(element.querySelectorAll(`.${this.classNames.item}`));
        this.toggles = Array.from(element.querySelectorAll(`.${this.classNames.toggle}`));
        this.header = element.closest('header');
        this.expandedItem = {
            toggle: null,
            submenu: null,
        };
        this.desktopBreakpoint = 1024;
        this.itemHeight = this.element.clientHeight / this.parentItems.length;
        this.startUpTimer = true;
        this.initialize();
    }
    initialize() {
        this.addEventListeners();
        setTimeout(() => {
            this.startUpTimer = false;
        }, 1000);
    }
    addEventListeners() {
        this.parentItems.forEach((el) => {
            el.addEventListener('mouseenter', (e) => this.handleMouseEnter(e));
            el.addEventListener('mouseleave', (e) => this.handleMouseLeave(e));
        });
        this.toggles.forEach((el) => {
            el.addEventListener('click', (e) => this.handleClick(e));
        });
        this.element.addEventListener('keydown', (e) => this.handleKeyDown(e));
    }
    handleMouseEnter(event) {
        if (window.innerWidth < this.desktopBreakpoint || this.startUpTimer) {
            return;
        }
        const target = event.currentTarget;
        const toggle = target.querySelector(`.${this.classNames.toggle}`);
        const submenu = target.querySelector(`.${this.classNames.submenu}`);
        this.openDropdown(toggle, submenu);
    }
    handleMouseLeave(event) {
        if (window.innerWidth < this.desktopBreakpoint) {
            return;
        }
        this.startUpTimer = false;
        const target = event.currentTarget;
        const toggle = target.querySelector(`.${this.classNames.toggle}`);
        const submenu = target.querySelector(`.${this.classNames.submenu}`);
        const parent = toggle.closest(`.${this.classNames.parent}`);
        const parentSubmenu = parent.closest(`.${this.classNames.submenu}`);
        if (parentSubmenu) {
            return;
        }
        this.closeDropdown(toggle, submenu);
    }
    handleClick(event) {
        var _a;
        const toggle = event.currentTarget;
        const submenu = (_a = toggle.parentElement) === null || _a === void 0 ? void 0 : _a.nextElementSibling;
        if (toggle.getAttribute('aria-expanded') === 'true') {
            this.closeDropdown(toggle, submenu);
        }
        else {
            this.openDropdown(toggle, submenu);
        }
    }
    closeDropdown(toggle, submenu) {
        const parent = toggle.closest(`.${this.classNames.parent}`);
        const parentSubmenu = parent.closest(`.${this.classNames.submenu}`);
        if (window.innerWidth < this.desktopBreakpoint) {
            submenu.style.maxHeight = '0px';
        }
        else if (parentSubmenu) {
            submenu.style.maxHeight = '0px';
        }
        toggle.setAttribute('aria-expanded', 'false');
        submenu.setAttribute('aria-hidden', 'true');
        parent.removeAttribute('open');
        const topParent = parent.closest(`.${this.classNames.item}`);
        if (!topParent) {
            this.expandedItem = {
                toggle: null,
                submenu: null,
            };
        }
        else {
            this.expandedItem = {
                toggle: topParent.querySelector(`.${this.classNames.toggle}`),
                submenu: topParent.querySelector(`.${this.classNames.submenu}`),
            };
        }
    }
    openDropdown(toggle, submenu) {
        const submenuHeight = submenu.scrollHeight;
        const parent = toggle.closest(`.${this.classNames.parent}`);
        const parentSubmenu = parent.closest(`.${this.classNames.submenu}`);
        if (window.innerWidth < this.desktopBreakpoint) {
            submenu.style.maxHeight = `${submenuHeight}px`;
            if (parentSubmenu) {
                const parentSubmenuHeight = parentSubmenu.offsetHeight;
                parentSubmenu.style.maxHeight = `${parentSubmenuHeight + submenuHeight}px`;
            }
        }
        else if (parentSubmenu) {
            submenu.style.maxHeight = `${submenuHeight}px`;
        }
        submenu.setAttribute('aria-hidden', 'false');
        toggle.setAttribute('aria-expanded', 'true');
        parent.setAttribute('open', '');
        this.expandedItem = {
            toggle,
            submenu,
        };
    }
    handleKeyDown(event) {
        if (!this.expandedItem.toggle || !this.expandedItem.submenu) {
            return;
        }
        const parent = this.expandedItem.toggle.closest(`.${this.classNames.parent}`);
        const toggle = this.expandedItem.toggle;
        const submenu = this.expandedItem.submenu;
        if (event.key === 'Tab') {
            const focusedElement = parent === null || parent === void 0 ? void 0 : parent.querySelector(':focus');
            const focusableElements = parent === null || parent === void 0 ? void 0 : parent.querySelectorAll('a');
            if (!focusedElement || !focusableElements) {
                return;
            }
            const firstFocusableElement = focusableElements[0];
            const lastFocusableElement = focusableElements[focusableElements.length - 1];
            if (!event.shiftKey && focusedElement === lastFocusableElement) {
                if (window.innerWidth >= this.desktopBreakpoint) {
                    this.closeDropdown(toggle, submenu);
                }
                return;
            }
            if (event.shiftKey && focusedElement === firstFocusableElement) {
                this.closeDropdown(toggle, submenu);
                return;
            }
        }
        if (event.key == 'Escape') {
            this.closeDropdown(toggle, submenu);
        }
    }
}
export default PrimaryNav;
