class Example {
    constructor(element) {
        this.element = element;
        this.config = this.element.dataset.config ? JSON.parse(this.element.dataset.config) : {};
        this.classNames = {
            greeting: `random-greeting`,
        };
        this.randomGreeting = this.element.querySelector(`.${this.classNames.greeting}`);
        this.random = '';
        this.initialize();
    }
    initialize() {
        this.addEventListeners();
    }
    addEventListeners() {
        this.element.addEventListener('click', (event) => {
            const currentTarget = event.currentTarget;
            if (currentTarget.matches('button')) {
                const buttonText = currentTarget.querySelector('data-button-text');
                if (!buttonText) {
                    return;
                }
                buttonText.textContent = this.config.clicked;
            }
        });
        setInterval(() => {
            var _a;
            if ((_a = this.config) === null || _a === void 0 ? void 0 : _a.greetings) {
                if (!this.randomGreeting) {
                    return;
                }
                this.random = this.config.greetings[Math.floor(Math.random() * this.config.greetings.length)];
                this.randomGreeting.textContent = this.random;
            }
        }, 500);
    }
}
export default Example;
