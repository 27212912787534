import addAriaLabelToExternalLinks from './utilities/external-links';
import ModuleInitializer from './utilities/module-initializer';
import PrimaryNav from './modules/primary-nav';
import OEmbed from './modules/oembed';
import SocialShare from './modules/social-share';
import Toggle from './modules/toggle';
import Cookiebot from './modules/cookiebot';
import Example from './modules/example-module';
import TextZoom from './modules/text-zoom';
import TreeMenuNav from './modules/tree-menu-nav';
addAriaLabelToExternalLinks();
ModuleInitializer({
    'primary-nav': PrimaryNav,
    oembed: OEmbed,
    toggle: Toggle,
    cookiebot: Cookiebot,
    'example-module': Example,
    'social-share': SocialShare,
    'text-zoom': TextZoom,
    'tree-menu-nav': TreeMenuNav,
});
