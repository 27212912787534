import MrToggle from "../utilities/mr-toggle";
class Toggle {
    constructor(element) {
        this.element = element;
        this.initialize();
    }
    initialize() {
        const options = {
            moduleName: "toggle",
            useHover: false,
        };
        new MrToggle(this.element, options);
    }
}
export default Toggle;
