export const scrollLock = {
    lockedElements: new Set(),
    disableScroll(element) {
        if (this.lockedElements.has(element))
            return;
        this.lockedElements.add(element);
        const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.overflow = "hidden";
        document.body.style.paddingRight = `${scrollBarWidth}px`;
        element.style.overflow = "hidden";
    },
    clearAllLocks() {
        this.lockedElements.clear();
        document.body.style.overflow = "";
        document.body.style.paddingRight = "";
    },
};
