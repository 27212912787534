/**
 * Helper to check what viewport state is active
 * Example usage: ResponsiveHelper.isMobile()
 */
export default class ResponsiveHelper {
    constructor() {
        this.sm = 768;
        this.md = 1024;
        this.lg = 1200;
    }
    /**
     * Check if user is in the mobile viewport (768px <=)
     */
    isMobile() {
        const windowWidth = window.innerWidth;
        return windowWidth <= this.sm - 1;
    }
    /**
     * Check if user is in the tablet portrait viewport (768px >= && <= 991px)
     */
    isTabletPortrait() {
        const windowWidth = window.innerWidth;
        return windowWidth >= this.sm && windowWidth <= 991;
    }
    /**
     * Check if user is in the tablet landscape viewport (992px >= && <= 1024px)
     */
    isTabletLandscape() {
        const windowWidth = window.innerWidth;
        return windowWidth >= 992 && windowWidth <= this.md;
    }
    /**
     * Check if user is in the desktop viewport (1200px >=)
     */
    isDesktop() {
        const windowWidth = window.innerWidth;
        return windowWidth >= this.lg;
    }
    /**
     * Check if user is atleast in the tablet portrait viewport (768px >=)
     */
    atLeastTabletPortrait() {
        const windowWidth = window.innerWidth;
        return windowWidth >= this.sm;
    }
    /**
     * Check if user is smaller than the tablet portrait viewport (768px <=)
     */
    smallerThanTabletPortrait() {
        const windowWidth = window.innerWidth;
        return windowWidth <= this.sm;
    }
    /**
     * Check if user is atleast in the tablet landscape viewport (992px >=)
     */
    atLeastTabletLandscape() {
        const windowWidth = window.innerWidth;
        return windowWidth >= 992;
    }
    /**
     * Check if user is smaller than the tablet landscape viewport (992px <=)
     */
    smallerThanTabletLandscape() {
        const windowWidth = window.innerWidth;
        return windowWidth <= 992;
    }
    /**
     * Check if user viewport is smaller than custom size (e.g. 900px <=)
     *
     * @param  size
     */
    smallerThanCustomSize(size) {
        const windowWidth = window.innerWidth;
        return windowWidth <= size;
    }
    /**
     * Check if user is atleast in custom size viewport (e.g. 900px >=)
     *
     * @param  size
     */
    atLeastCustomSize(size) {
        const windowWidth = window.innerWidth;
        return windowWidth >= size;
    }
    /**
     * Check if user is on a real device
     */
    isRealDevice() {
        return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
            typeof window.orientation !== "undefined");
    }
}
