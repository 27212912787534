/**
 * Returns a NodeList of focusable elements that exist within the passed parent HTMLElement
 *
 * @param  parent
 * @return {NodeList} The focusable elements that we can find
 */
export const getAllFocusableElements = (parent) => {
    let items = Array.from(parent.querySelectorAll(`
		iframe,
		object,
		embed,
		a[href],
		area[href],
		button,
		input,
		select,
		textarea,
		details,
		summary:not(:disabled),
		*[contenteditable]
		`));
    // Remove focusable elements that are hidden / disabled
    items = items.filter((item) => {
        const aria = item.getAttribute("aria-hidden");
        const ariaHidden = aria === "true" ? true : false;
        // Remove elements that are hidden with CSS
        if (window.getComputedStyle(item, null).display === "none") {
            return false;
        }
        return !item.hasAttribute("disabled") && !ariaHidden;
    });
    // Remove focusable elements inside hidden elements
    items = items.filter((item) => {
        var _a;
        const aria = (_a = item.closest("[aria-hidden]")) === null || _a === void 0 ? void 0 : _a.getAttribute("aria-hidden");
        const ariaHidden = aria === "true" ? true : false;
        return !ariaHidden;
    });
    // Remove items with a tabindex="-1" which are not focusable
    return items.filter((item) => {
        return item.getAttribute("tabindex") !== "-1";
    });
};
/**
 * Returns the first item in the NodeList of focusable elements that exist within the passed parent HTMLElement
 *
 * @param  parent
 * @return {HTMLElement} The first focusable element
 */
export const getFirstFocusableElement = (parent) => {
    const all = getAllFocusableElements(parent);
    return all[0];
};
