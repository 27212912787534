class Cookiebot {
    constructor(element) {
        this.element = element;
        this.config = this.element.dataset.config ? JSON.parse(this.element.dataset.config) : {};
        this.initialize();
    }
    initialize() {
        if (typeof Cookiebot === 'undefined') {
            return;
        }
        this.addEventListeners();
    }
    addEventListeners() {
        this.element.addEventListener('click', this.handleClick.bind(this));
    }
    handleClick(event) {
        var _a;
        event.preventDefault();
        let action = 'renew';
        if ((_a = this.config) === null || _a === void 0 ? void 0 : _a.action) {
            action = this.config.action;
        }
        switch (action) {
            case 'renew':
                // @ts-expect-error Ignore for now, Cookiebot is missing a types package
                Cookiebot.renew();
                break;
            case 'withdraw':
                // @ts-expect-error Ignore for now, Cookiebot is missing a types package
                Cookiebot.withdraw();
                break;
        }
    }
}
export default Cookiebot;
