class TreeMenuNav {
    constructor(element) {
        this.element = element;
        this.mainClass = 'widget-tree-menu-nav';
        this.classNames = {
            parent: `${this.mainClass}__item--parent`,
            ancestorActive: `${this.mainClass}__item--ancestor-active`,
            toggle: `${this.mainClass}__toggle`,
            submenu: `${this.mainClass}-sub`,
        };
        this.parentItems = Array.from(element.querySelectorAll(`.${this.classNames.parent}`));
        this.ancestorActiveItems = Array.from(element.querySelectorAll(`.${this.classNames.ancestorActive}`));
        this.toggles = Array.from(element.querySelectorAll(`.${this.classNames.toggle}`));
        this.initialize();
    }
    initialize() {
        this.addEventListeners();
        this.openCurrent();
    }
    addEventListeners() {
        this.toggles.forEach((el) => {
            el.addEventListener('click', (e) => this.handleClick(e));
        });
    }
    openCurrent() {
        this.ancestorActiveItems.forEach((el) => {
            const toggle = el.querySelector(`.${this.classNames.toggle}`);
            const submenu = toggle === null || toggle === void 0 ? void 0 : toggle.nextElementSibling;
            if (toggle && submenu) {
                this.openDropdown(toggle, submenu);
            }
        });
    }
    handleClick(event) {
        const toggle = event.currentTarget;
        const submenu = toggle.nextElementSibling;
        if (toggle.getAttribute('aria-expanded') === 'true') {
            this.closeDropdown(toggle, submenu);
        }
        else {
            this.openDropdown(toggle, submenu);
        }
    }
    closeDropdown(toggle, submenu) {
        const parent = toggle.closest(`.${this.classNames.parent}`);
        toggle.setAttribute('aria-expanded', 'false');
        submenu.setAttribute('aria-hidden', 'true');
        parent.removeAttribute('open');
    }
    openDropdown(toggle, submenu) {
        const parent = toggle.closest(`.${this.classNames.parent}`);
        submenu.setAttribute('aria-hidden', 'false');
        toggle.setAttribute('aria-expanded', 'true');
        parent.setAttribute('open', '');
    }
}
export default TreeMenuNav;
