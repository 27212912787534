class OEmbed {
    constructor(element) {
        this.element = element;
        this.dataAttribute = 'data-oembed';
        this.classNames = {
            consent: 'js-consent-button',
            videoPlay: 'btn--js-video-play',
            audioPlay: 'btn--js-audio-play',
        };
        this.button = this.element.querySelector(`.${this.classNames.consent}`);
        this.isCookiebot = this.element.dataset.cookiebot;
        this.source = this.element.dataset.source;
        this.type = this.element.dataset.type;
        this.disableNotice = this.element.dataset.disableNotice;
        this.cookiebotOpenerElement = null;
        this.cookiebotOpenerButton = null;
        this.initialize();
    }
    initialize() {
        if (this.disableNotice === '1') {
            this.showEmbed();
            return;
        }
        if (this.isCookiebot && typeof Cookiebot !== 'undefined') {
            this.initCookiebot();
        }
        else {
            this.initDefault();
        }
    }
    initCookiebot() {
        var _a, _b;
        if ((_a = Cookiebot.consent) === null || _a === void 0 ? void 0 : _a.marketing) {
            this.showEmbed();
        }
        else {
            window.addEventListener('CookiebotOnAccept', () => {
                var _a, _b;
                if ((_a = Cookiebot.consent) === null || _a === void 0 ? void 0 : _a.marketing) {
                    this.showEmbed();
                }
                else {
                    (_b = this.cookiebotOpenerButton) === null || _b === void 0 ? void 0 : _b.focus();
                }
            });
            window.addEventListener('CookiebotOnDecline', () => {
                var _a;
                (_a = this.cookiebotOpenerButton) === null || _a === void 0 ? void 0 : _a.focus();
            });
            (_b = this.button) === null || _b === void 0 ? void 0 : _b.addEventListener('click', () => {
                // store where the click originated from
                this.cookiebotOpenerButton = this.button;
                this.cookiebotOpenerElement = this.element;
                Cookiebot.show();
                // focus first focusable element in cookiebanner
                window.addEventListener('CookiebotOnDialogDisplay', () => {
                    const banner = Cookiebot.dialog.DOM;
                    const firstFocusable = banner === null || banner === void 0 ? void 0 : banner.querySelector('a,button,select,input,textarea');
                    firstFocusable === null || firstFocusable === void 0 ? void 0 : firstFocusable.focus();
                });
            });
        }
    }
    initDefault() {
        var _a;
        if (this.source && window.localStorage.getItem(this.source)) {
            this.showEmbed();
        }
        else {
            (_a = this.button) === null || _a === void 0 ? void 0 : _a.addEventListener('click', () => {
                // store where the click originated from
                this.cookiebotOpenerButton = this.button;
                this.cookiebotOpenerElement = this.element;
                if (this.source) {
                    window.localStorage.setItem(this.source, 'true');
                    const event = new CustomEvent('sourceAccepted', {
                        bubbles: true,
                        detail: { source: () => this.source },
                    });
                    document.dispatchEvent(event);
                }
            });
            window.addEventListener('sourceAccepted', (e) => {
                if (e.detail.source() === this.source) {
                    this.showEmbed();
                }
            });
        }
    }
    showEmbed(embed) {
        if (embed === undefined) {
            embed = this.element;
        }
        const content = embed.querySelector('template');
        if (!content) {
            return;
        }
        const OEmbedContent = embed.querySelector(`[${this.dataAttribute}="content"]`);
        if (!OEmbedContent) {
            return;
        }
        OEmbedContent.innerHTML = content.innerHTML;
        if (this.type === 'video') {
            this.lazyLoadVideo(embed);
        }
        else if (this.type === 'audio') {
            this.lazyLoadAudio(embed);
        }
        this.cookiebotOpenerButton = null;
        this.cookiebotOpenerElement = null;
    }
    lazyLoadVideo(embed) {
        var _a;
        const videoElement = embed.querySelector(`[${this.dataAttribute}="video"]`);
        if (!videoElement) {
            return;
        }
        const playButton = (_a = this.cookiebotOpenerElement) === null || _a === void 0 ? void 0 : _a.querySelector(`.${this.classNames.videoPlay}`);
        playButton === null || playButton === void 0 ? void 0 : playButton.focus();
        videoElement.addEventListener('click', () => {
            this.playVideo(videoElement);
        });
    }
    lazyLoadAudio(embed) {
        var _a, _b;
        const audioElement = embed.querySelector(`[${this.dataAttribute}="audio"]`);
        if (!audioElement) {
            return;
        }
        const title = (_a = audioElement === null || audioElement === void 0 ? void 0 : audioElement.querySelector('iframe')) === null || _a === void 0 ? void 0 : _a.getAttribute('title');
        if (title && (title === null || title === void 0 ? void 0 : title.length) > 0) {
            const titleElement = audioElement.querySelector(`[${this.dataAttribute}="audio-title"]`);
            if (!titleElement) {
                return;
            }
            titleElement.textContent = title;
        }
        const playButton = (_b = this.cookiebotOpenerElement) === null || _b === void 0 ? void 0 : _b.querySelector(`.${this.classNames.audioPlay}`);
        playButton === null || playButton === void 0 ? void 0 : playButton.focus();
        audioElement.addEventListener('click', () => {
            this.playAudio(audioElement);
        });
    }
    playVideo(videoElement) {
        const iframe = videoElement.querySelector('iframe');
        const src = iframe === null || iframe === void 0 ? void 0 : iframe.getAttribute('data-src');
        iframe === null || iframe === void 0 ? void 0 : iframe.setAttribute('src', src + '&autoplay=1&disablekb=1&keyboard=0');
        const playButton = videoElement.querySelector(`.${this.classNames.videoPlay}`);
        const thumbnail = videoElement.querySelector(`[${this.dataAttribute}="thumbnail"]`);
        playButton === null || playButton === void 0 ? void 0 : playButton.remove();
        thumbnail === null || thumbnail === void 0 ? void 0 : thumbnail.remove();
    }
    playAudio(embedElement) {
        const iframe = embedElement.querySelector('iframe');
        const src = iframe === null || iframe === void 0 ? void 0 : iframe.getAttribute('data-src');
        if (!src || !iframe) {
            return;
        }
        iframe.allow = 'autoplay';
        iframe.setAttribute('src', src + '&auto_play=true&hide_related=true&show_comments=false&show_reposts=false');
        const placeholder = embedElement.querySelector(`[${this.dataAttribute}="placeholder"]`);
        placeholder === null || placeholder === void 0 ? void 0 : placeholder.remove();
    }
}
export default OEmbed;
